<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">14th MONTH PAYROLL</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="released_no"
            dense
            label="Released #"
            :items="['1st Semi-Annual','2nd Semi-Annual']"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="selection"
            class="mx-2"
            dense
            outlined
            label="Selection"
            :items="['Active','Temporary Deactivate','Permanent Deactivate']"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="status"
            class="mx-2"
            dense
            outlined
            label="Status"
            :items="['Approved','Monitoring 14th Month Pay']"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_category"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            outlined
            v-model="bank"
            class="mx-2"
            :items="bank_items"
            item-value="bank_type"
            item-text="bank_type"
            label="Bank"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_bank"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            outlined
            v-model="batch_no"
            class="mx-2"
            :items="batch_no_items"
            item-value="batch_no"
            item-text="batch_no"
            label="Batch"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_data"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="released_no==='1st Semi-Annual'?headers:headers3"
                    :items="payroll_data"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>14th Month Payroll Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title v-if="!printingss && payroll_data.length > 0">Print</v-toolbar-title>
            <v-icon
              v-if="!printingss && payroll_data.length > 0"
              color="success"
              @click="print_data()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="printingss"
            ></v-progress-circular>
            <v-toolbar-title v-if="!printingss && payroll_data.length > 0">Print Bank
              Generation
              Format
            </v-toolbar-title>
            <v-icon
              v-if="!printingss && payroll_data.length > 0"
              color="success"
              @click="print_data3()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="printingss"
            ></v-progress-circular>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="status==='Approved'">
              {{ item.employee.last_name+','+item.employee.first_name+' '+item.employee.middle_name
              }}
            </td>
            <td v-else>
              {{ item.name
              }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.jan_amount) }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.feb_amount) }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.mar_amount) }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.apr_amount) }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.may_amount) }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              {{ formatPrice(item.jun_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.jul_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.aug_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.sep_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.oct_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.nov_amount) }}
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              {{ formatPrice(item.dec_amount) }}
            </td>
            <td>
              {{ formatPrice(item.total_amount) }}
            </td>
            <td>
              {{ item.remarks}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiPrinter,
    mdiInformationOutline,
    mdiAccountSearch
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      released_no: '',
      selection: '',
      printingss: false,
      status: '',
      year: '',
      year_items: [],
      payroll_data: [],
      duration: '',
      sorted_by: 'Name',
      bank_type: 'All',
      bank_type_items: [],
      bank: 'All',
      bank_items: [],
      batch_no: '',
      batch_no_items: [],
      category_id: '',
      category_items: [],
      category_items2: [],
      search: '',
      headers: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Jan.', value: 'employee.last_name', sortable: false},
        {text: 'Feb.', value: 'amount', sortable: false},
        {text: 'Mar.', value: 'bank_code_name', sortable: false},
        {text: 'Apr.', value: 'bank_code_name', sortable: false},
        {text: 'May', value: 'bank_code_name', sortable: false},
        {text: 'Jun.', value: 'bank_code_name', sortable: false},
        {text: 'Total', value: 'bank_code_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
      ],
      headers3: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Jul.', value: 'bank_code_name', sortable: false},
        {text: 'Aug.', value: 'bank_code_name', sortable: false},
        {text: 'Sep.', value: 'bank_code_name', sortable: false},
        {text: 'Oct.', value: 'bank_code_name', sortable: false},
        {text: 'Nov.', value: 'bank_code_name', sortable: false},
        {text: 'Dec.', value: 'bank_code_name', sortable: false},
        {text: 'Total', value: 'bank_code_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('category', ['list_of_category_have_thirten_month_pay']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee', 'initialize_payroll_selected_category', 'initialize_payroll', 'payroll']),
      ...mapActions('fourteen_month_pay', ['initialize_fourteen_month_payroll']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
            this.category_item2 = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year);
        data.append('status', this.status);
        data.append('remarks', this.selection);
        data.append('released_no', this.released_no === '1st Semi-Annual' ? 1 : 2);
        if (this.status === 'Approved') {
          this.category_items = []
          this.initialize_fourteen_month_payroll(data)
            .then(response => {
              this.category_items = response.data
              this.category_id = ''
              this.batch_no = ''
              this.bank_items = []
              this.batch_no_items = []
              this.payroll_data = []
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.category_items = this.category_item2
          this.selected_category(this.category_id)
        }
      },
      selected_category(value) {
        if (this.status === 'Approved') {
          var index = this.category_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.bank_items = this.category_items[index].bank
            this.batch_no = ''
            this.bank = ''
            this.payroll_data = []
            this.batch_no_items = []
          }
        } else {
          const data = new FormData()
          data.append('year', this.year);
          data.append('remarks', this.selection);
          data.append('category_id', this.category_id);
          data.append('released_no', this.released_no);
          this.initialize_fourteen_month_payroll(data)
            .then(response => {
              this.payroll_data = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_bank(value) {
        if (this.status === 'Approved') {
          var index = this.bank_items.map(function (x) {
            return x.bank_type;
          }).indexOf(value)
          if (index != -1) {
            this.batch_no = ''
            this.batch_no_items = this.bank_items[index].batch
          }
        } else {
          //Code Here
        }
      },
      selected_data(value) {
        if (this.status === 'Approved') {
          var index = this.batch_no_items.map(function (x) {
            return x.batch_no;
          }).indexOf(value)
          if (index != -1) {
            this.payroll_data = this.batch_no_items[index].data
          }
        } else {
          //Code Here
        }
      },
      async print_data() {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [95, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 55, 40, 80,]
        if (this.released_no === '2nd Semi-Annual') {
          payments_array.push([
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Jul.', alignment: 'left', style: 'main_info'},
            {text: 'Aug.', alignment: 'left', style: 'main_info'},
            {text: 'Sep.', alignment: 'left', style: 'main_info'},
            {text: 'Oct.', alignment: 'left', style: 'main_info'},
            {text: 'Nov.', alignment: 'left', style: 'main_info'},
            {text: 'Dec.', alignment: 'left', style: 'main_info'},
            {text: 'Total', alignment: 'left', style: 'main_info'},
            {text: 'Status', alignment: 'left', style: 'main_info'},
          ])
        } else {
          payments_array.push([
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Jan.', alignment: 'left', style: 'main_info'},
            {text: 'Feb.', alignment: 'left', style: 'main_info'},
            {text: 'Mar.', alignment: 'left', style: 'main_info'},
            {text: 'Apr.', alignment: 'left', style: 'main_info'},
            {text: 'May', alignment: 'left', style: 'main_info'},
            {text: 'Jun.', alignment: 'left', style: 'main_info'},
            {text: 'Total', alignment: 'left', style: 'main_info'},
            {text: 'Status', alignment: 'left', style: 'main_info'},
          ])
        }

        var tottt = 0
        var is_approved = (this.status === 'Approved')
        if (this.released_no === '2nd Semi-Annual') {
          this.payroll_data.forEach(function (item, index, payment) {
            tottt += parseFloat(item.total_amount)
            payments_array.push([
              {
                text: is_approved ? item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name : item.name,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.jul_amount === 0 ? '' : (item.jul_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.aug_amount === 0 ? '' : (item.aug_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.sep_amount === 0 ? '' : (item.sep_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.oct_amount === 0 ? '' : (item.oct_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.nov_amount === 0 ? '' : (item.nov_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.dec_amount === 0 ? '' : (item.dec_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.total_amount === 0 ? '' : (item.total_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.remarks,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push([
                {
                  text: 'TOTAL',
                  alignment: 'right',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: (tottt / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
              ])
            }
          })
        } else {
          this.payroll_data.forEach(function (item, index, payment) {
            tottt += parseFloat(item.total_amount)
            payments_array.push([
              {
                text: is_approved ? item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name : item.name,
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.jan_amount === 0 ? '' : (item.jan_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.feb_amount === 0 ? '' : (item.feb_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.mar_amount === 0 ? '' : (item.mar_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.apr_amount === 0 ? '' : (item.apr_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.may_amount === 0 ? '' : (item.may_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.jun_amount === 0 ? '' : (item.jun_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.total_amount === 0 ? '' : (item.total_amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: item.remarks,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push([
                {
                  text: 'TOTAL',
                  alignment: 'right',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: '',
                },
                {
                  text: (tottt / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
              ])
            }
          })
        }


        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'YEAR OF: ',
                  {
                    text: this.year_items[
                      this.year_items
                        .map(function (x) {
                          return x.year
                        })
                        .indexOf(this.year)
                      ].year,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  is_approved ? 'APPROVED' : 'NOT YET APPROVED',
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BATCH #: ',
                  {
                    text: this.batch_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          }, {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BANK: ',
                  {
                    text: this.bank,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'LABOTTEGA',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: '14TH MONTH PAYROLL REPORT (' + this.released_no + ')',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      },
      async print_data3() {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [100, 100, 150, 150]
        payments_array.push([
          {text: 'Account #', alignment: 'left', style: 'main_info'},
          {text: '14th Month Pay', alignment: 'left', style: 'main_info'},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Credit To', alignment: 'left', style: 'main_info'},
        ])
        var deductions = 0
        var tottt = 0
        var net = 0
        var is_approved = (this.status === 'Approved')
        this.payroll_data.forEach(function (item, index, payment) {
          tottt += parseFloat(item.total_amount)
          payments_array.push([
            {
              text: item.bank_account_no.replaceAll('-', ''),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.total_amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: is_approved ? item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name : item.name,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.bank_account_name,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: (tottt / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'YEAR OF: ',
                  {
                    text: this.year_items[
                      this.year_items
                        .map(function (x) {
                          return x.year
                        })
                        .indexOf(this.year)
                      ].year,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  is_approved ? 'APPROVED' : 'NOT YET APPROVED',
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BATCH #: ',
                  {
                    text: this.batch_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          }, {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BANK: ',
                  {
                    text: this.bank,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'LABOTTEGA',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: '14TH MONTH PAYROLL REPORT  (' + this.released_no + ')',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      },
    },
  }
</script>
